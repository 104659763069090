import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import logo from "../img/logo-black.svg";
import scrollTo from "gatsby-plugin-smoothscroll";
import EnquiryForm from "../components/enquiry";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import ContactUsBanner from "../img/contact-us.jpg";
export default function ContactUs() {
  const { loaded, error, formCreated } = useHubspotForm({
    region: "na1",
    portalId: "19573488",
    formId: "1bc4eac9-1a8c-476e-8e4f-58cff8896631",
    target: "#my-hubspot-form-new",
  });
  return (
    <div>
      <Helmet>
        <title>OIC - Frequently Asked Questions (FAQ)</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
        <div style={{ padding: "90px 0", background: "#000" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <h1
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    color: "#fff",
                  }}
                >
                  FAQ
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row ">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <p>
                <b>1. What is OIC?</b>
                <br></br>
                <br></br>
                OiC is an online marketplace platform primarily built for the
                optical sector to ease the challenge of distributors/retailers
                under a single roof to digitally increase their efficiency.
                Offering a wide variety of eyewear from different leading brands
                of optics. The optical industry in India had a gaping slot for a
                retail chain that could provide end-to-end solutions for vision
                challenges. To add to it, there has been a pressing market for
                quality and affordable eyewear from all top brands.
                <br />
                <br />
                <br></br>
                <b>2. What are the Top brands available on the OIC platform?</b>
                <br></br>
                <br></br>
                Top brands include Ray-Ban, DKNY, Titan, Fastrack, VELOCITY, and
                so on…
                <br></br>
                <br></br>
                <br></br>
                <b>3. Where can I find my frame measurements?</b>
                <br></br>
                <br></br>
                When you pick a pair of frames or sunglasses that you love, look
                on the inside of the frames&rsquo; temples (or arms) where you
                will see a series of numbers like this – 50/19/135. The first
                number is simply the horizontal width of the lens and usually
                ranges from 40 mm to 60 mm. The second number is the bridge size
                ranging from 14 mm to 24 mm and is the distance between the two
                lenses. The temple length is the last number measured from the
                end piece to the tip. This length falls between 120 mm and 150
                mm.
                <br></br>
                <br></br>
                <b>4. What is an optical retailer&rsquo;s role?</b>
                <br></br>
                <br></br>A retailer is an intermediary or middleman that
                customers use to get products from the brand/distributor. The
                optical retail industry is one of the few industries that sell
                products and services, all depending on whether you need glasses
                or lenses for reading or sunglasses, varying from a necessity to
                luxury. The retailer sells the products to the end-user, which
                is a customer.&nbsp;<br></br>
                <br></br>
                <br></br>
                <b>5. What are Rimless Eyeglasses?</b>
                <br></br>
                <br></br>
                Bright and gloomy, rimless eyeglasses give your face a
                minimalist touch.&nbsp;
                <br></br> <br></br>
                <br></br>
                <b>6. What is 3D TRY-ON in OIC?</b>
                <br></br>
                <br></br>
                The customer experience is more important now. Engage the
                customer using the glasses from our pending 3D Live Virtual
                Try-on Technology patent. Try Now would redirect to 3D Try On.
                <br></br>
                <br></br>
                <br></br>
                <b>7. Can the retailer update inventory?</b>
                <br></br>
                <br></br>
                Nope, the retailer will not have access to update inventory on
                the OIC platform<br></br>
                <br></br>
                <br></br>
                <b> 8. What is Aviator Eyeglasses?</b>
                <br></br>
                <br></br>
                One of the most sought-after styles, aviator glasses, carries a
                classy appeal. Manage a bold look every day with the iconic
                aviator eyeglass frames
                <br></br>
                <br></br>
                <br></br>
                <b>9. What is OIC CRM?</b>
                <br></br>
                <br></br>
                Customer Relationship Management is a technique that helps
                organizations strengthen existing customer relationships and
                accelerate the acquisition of new customers. CRM is extremely
                comprehensive and interacts with all modules and POS systems in
                real-time. With its customer-centric features, this tool can
                help boost profits in the long run by enhancing customer
                retention.
                <br></br>
                <br></br>
                <br></br>
                <b>10. What is the use of Mirror Coating?</b>
                <br></br>
                <br></br>
                Mirror coating on sunglass lenses not only looks cool, but it
                can also improve visual comfort in extreme light conditions
                <br></br>
                <br></br>
                <br></br>
                <b>11. What is a Virtual Mirror?</b>
                <br></br>
                <br></br>A virtual mirror shows the photo of a user on a display
                as if it became a mirror. Customers seem to just like the new
                trend of digital try-on, where they get to adopt what they will
                purchase earlier than they buy. Virtual Mirror will take
                companies to the next stage. In the 3D view, the 3D Library has
                a list of items that make Try On to purchase before purchasing
                <br></br> <br></br>
                <br></br><b>12. Why Shop With Us?</b><br></br>
                <br></br>
                <ul>
                  <li>Exceptional Distributor Inventory&nbsp;</li>
                  <li>Exclusive &amp; Innovative brands collections</li>
                  <li>High-Quality Products Materials</li>
                  <li>Trendy additions</li>
                  <li>Retailer enhancement</li>
                  <li>Customer-friendly</li>
                </ul>
                <br></br>
                <br></br>
                <b>13. What is Blue Light Blocking?</b><br></br>
                <br></br>
                Lens coating with a blue light filter to reduce the amount of
                blue light reaching your eyes and reduce eye strain from digital
                displays
                <br></br>
                <br></br>
                <br></br>
                <b>14. What is 2D TRY ON in OIC Platform?</b><br></br>
                <br></br>
                Our OIC software recognizes facial highlights in the client's
                picture and we render the glasses on the clients face precisely
                and photograph everything being equal
                <br></br>
                <br></br>
                <br></br>
                <b>15. What is 3D TRY-ON in OIC?</b><br></br>
                <br></br>
                The customer experience is more important now. Engage the
                customer using the glasses from our pending 3D Live Virtual
                Try-on Technology patent. Try Now would redirect to 3D Try-On
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
